<template>
  <section class="h-auto bg-black">
    <div class="py-14 lg:py-24 px-8">
      <div class="flex flex-row justify-center items-center">
        <img
          v-motion-pop-visible
          src="../assets/prev-lead-title.png"
          class="max-h-[100px] lg:max-h-[120px] xl:max-h-[150px] h-full w-auto"
          alt="prev-leaderboard"
        />
      </div>
       <div v-if="prevloading == true" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold" style="font-family: Russo One, sans-serif;">Waiting for Reset...</div>
      <div
        v-if="prevloading == true"
        class="text-center text-white text-2xl md:text-4xl mt-10 font-bold"
        style="font-family: Russo One, sans-serif"
      >
        Loading Previous Leaderboard...
      </div>
      <div v-if="prevloading == false" class="flex justify-center items-center">
        <div class="hidden md:block">
          <label
            class="toggle-switch mt-8"
            style="font-family: Stalinist One, sans-serif"
          >
            <input
              type="checkbox"
              @change="changePrevLead()"
              v-model="selectedPrevLeaderboard"
            />
            <div
              class="slider border border-white"
              :class="
                selectedPrevLeaderboard == true
                  ? 'bg-gradient-to-r from-[#050505_8%] via-[#5C2027_19%] to-[#95006B]'
                  : 'bg-gradient-to-r from-[#050505_8%] via-[#5C2027_19%] to-[#370040]'
              "
            ></div>
            <span class="left-label">ROOBET</span>
            <span class="right-label">PACKDRAW</span>
          </label>
        </div>
        <div class="block md:hidden">
          <ul
            class="flex flex-wrap gap-2 text-xs font-medium text-center text-gray-500 mt-4"
            style="font-family: Stalinist One, sans-serif"
          >
            <li
              @click="
                selectedPrevLeaderboard = false;
                changePrevLead();
              "
              :class="
                selectedPrevLeaderboard == false
                  ? 'bg-gradient-to-r from-[#050505_8%] via-[#5C2027_19%] to-[#370040]'
                  : ''
              "
              class="rounded-2xl border border-white cursor-pointer"
            >
              <div class="inline-block px-4 py-2 text-white">Roobet</div>
            </li>
            <li
              @click="
                selectedPrevLeaderboard = true;
                changePrevLead();
              "
              :class="
                selectedPrevLeaderboard == true
                  ? 'bg-gradient-to-r from-[#050505_8%] via-[#5C2027_19%] to-[#95006B]'
                  : ''
              "
              class="rounded-2xl border border-white cursor-pointer"
            >
              <div class="inline-block px-4 py-2 text-white">Packdraw</div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="prevloading == false" class="hidden md:block">
        <div
          class="flex justify-center mt-8 mb:mb-24"
          style="font-family: Russo One, sans-serif"
        >
          <table class="w-full lg:w-auto border-separate border-spacing-y-6">
            <thead
              v-motion-slide-visible-bottom
              class="rounded-2xl"
              :class="
                selectedPrevLeaderboard == true
                  ? 'bg-gradient-to-r from-[#9B005D] via-[#350003] to-[#57003F]'
                  : 'bg-gradient-to-r from-[#840000] via-[#350003] to-[#57003F]'
              "
            >
              <tr>
                <th
                  class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left rounded-l-full"
                >
                  Ranks
                </th>
                <th
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center"
                >
                  User
                </th>
                <th
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center"
                >
                  Amount
                </th>
                <th
                  class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right rounded-r-full"
                >
                  Prize
                </th>
              </tr>
            </thead>
            <div class="mt-4"></div>
            <tbody>
              <tr
                v-motion-slide-visible-bottom
                v-for="(item, index) in displayedPrevLead"
                :key="index"
              >
                <td
                  class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left"
                >
                  <div class="flex flex-row items-center gap-2">
                    <div class="text-white">
                      {{ getOrdinalSuffix(index + 1) }}
                    </div>
                  </div>
                </td>
                <td
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                >
                  {{ item.username }}
                </td>
                <td
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                >
                  ${{
                    selectedPrevLeaderboard == false
                      ? item?.weightedWagered?.toFixed(2)
                      : item?.wagerAmount?.toFixed(2)
                  }}
                </td>
                <td
                  class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-right"
                >
                  ${{
                    selectedPrevLeaderboard == false
                      ? getRoobetRewardValue(index)
                      : getPackDrawRewardValue(index)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="prevloading == false" class="block md:hidden">
        <div
          class="flex justify-center mt-8 mb:mb-24 text-xl"
          style="font-family: Russo One, sans-serif"
        >
          <table class="w-full lg:w-auto">
            <thead v-motion-slide-visible-bottom>
              <tr>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left"
                >
                  Ranks
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                >
                  User
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                >
                  Amount
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right"
                >
                  Prize
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-motion-slide-visible-bottom
                v-for="(item, index) in displayedPrevLead"
                :key="index"
              >
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left"
                >
                  {{ getOrdinalSuffix(index + 1) }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                >
                  {{ item.username }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                >
                  ${{
                    selectedPrevLeaderboard == false
                      ? item?.weightedWagered?.toFixed(2)
                      : item?.wagerAmount?.toFixed(2)
                  }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right"
                >
                  ${{
                    selectedPrevLeaderboard == false
                      ? getRoobetRewardValue(index)
                      : getPackDrawRewardValue(index)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      displayedPrevLead: [],
      roobetPrevLeaderboard: [],
      packdrawPrevLeaderboard: [],
      prevloading: false,

      selectedPrevLeaderboard: false,
    };
  },
  methods: {
    async initPrevLeaderboard() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.prevloading = true;
      axios
        .get(`${baseUrl}/api/roobet/leaderboard?previous=true?`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        })
        .then((rest) => {
          this.roobetPrevLeaderboard = rest.data.leaderboard;

          axios
            .get(`${baseUrl}/api/packdraw/leaderboard?previous=true`, {
              headers: {
                "x-api-key": process.env.VUE_APP_X_API_KEY,
              },
            })
            .then((rest2) => {
              this.packdrawPrevLeaderboard = rest2.data.leaderboard;
              this.changePrevLead();
              this.prevloading = false;
            });
        });
    },
    changePrevLead() {
      if (this.selectedPrevLeaderboard == false) {
        const prevLeadeboard = this.roobetPrevLeaderboard;

        this.displayedPrevLead = prevLeadeboard.slice(0, 3).map((item) => {
          return item;
        });
      } else {
        const prevLeadeboard = this.packdrawPrevLeaderboard;

        this.displayedPrevLead = prevLeadeboard.slice(0, 8).map((item) => {
          return item;
        });
      }
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRoobetRewardValue(rank) {
      const prizes = [200, 120, 80];
      if (rank >= 11) {
        return 25;
      }
      return prizes[rank];
    },
    getPackDrawRewardValue(rank) {
      const prizes = [200, 100, 50, 50];
      if (rank >= 4) {
        return 25;
      }
      return prizes[rank];
    },
  },
  mounted() {
    this.initPrevLeaderboard();
  },
};
</script>

<style scoped>
.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 393px;
  height: 56px;
  background: linear-gradient(to right, #140000, #440000 34%);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  left: 4px;
  width: 192px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  transform: translateX(192px);
}

.toggle-switch span {
  color: white;
  font-size: 15px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;
}

.toggle-switch .left-label {
  position: absolute;
  left: 35px;
}

.toggle-switch .right-label {
  position: absolute;
  right: 23px;
}
</style>
